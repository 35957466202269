<template>
  <div class="spotlight card">
    <AtomsIcon
      v-if="type === 'article'"
      class="spotlight-type card-type"
      name="document-text"
    />
    <Transition v-if="type === 'video' && video?.url" name="card-video">
      <div v-if="active" class="spotlight-video card-video">
        <AtomsButton
          :icon="{ name: 'x-mark' }"
          plain
          class="spotlight-video-close card-video-close"
          title="Close video"
          @click="active = false"
        />
        <video
          ref="videoElement"
          class="spotlight-video-el card-video-el"
          preload="none"
          :poster="poster"
          :src="video.url"
          controls
        />
      </div>
    </Transition>
    <AtomsButton
      class="card-btn spotlight-btn plain"
      :to="finalProps.to"
      :class="{ 'has-link': type === 'video' || !!finalProps.to }"
      :element="finalProps.to ? undefined : 'div'"
      plain
      @click="handleClick()"
    >
      <div class="spotlight-image">
        <AtomsImage
          v-if="finalProps.image && finalProps.image.id"
          class="spotlight-image-element"
          :alt="title"
          :image="finalProps.image"
          :zoom="false"
          :delay="delay"
        />
      </div>
      <AtomsHeading class="spotlight-heading" :id="finalProps.id" :text="finalProps.title" 
      :delay="delay + 0.1" />
      <p 
        v-if="finalProps.text"
        :style="{ animationDelay: delay + 0.2 + 's' }"
        v-text="finalProps.text"
      />
      <span v-if="finalProps.to || type === 'video'" class="card-button spotlight-button">
        {{
          button
            ? button
            : type === 'video'
            ? 'Play video'
            : type === 'article' || type === 'story'
            ? `Read ${type}`
            : 'Read more'
        }}
        <PlayIcon
          v-if="type === 'video'"
        />
        <AtomsIcon v-else name="arrow-long-right" />
      </span>
    </AtomsButton>
  </div>
</template>

<script lang="ts" setup>
import type { SanityImage } from '~/types/groq'
import type { Video } from '~/types'
import PlayIcon from '~/assets/svg/play.svg'

const props = defineProps({
  slug: { type: String, default: undefined },
  to: {
    type: [String, Object, undefined],
    default: undefined,
  },
  delay: { type: Number, default: 0 },
  title: { type: String, default: undefined },
  type: { type: String, default: undefined },
  text: { type: String, default: undefined },
  internal: { type: Object, default: undefined },
  quote: { type: String, default: undefined },
  button: { type: String, default: undefined },
  article: { type: Object as () => Video, default: undefined },
  video: { type: Object as () => Video, default: undefined },
  image: {
    type: Object as () => SanityImage,
    default: () => {}
  }
})

const poster = props.image && getImageUrl(props.image, { width: 1200 })

const videoElement = ref<HTMLVideoElement | null>(null)

const active = ref(false)

const finalProps = computed(() => ({
  ...props,
  id: 'Spotlight on ' + props.title,
  title: [
    {
      style: 'secondary',
      children: [{ text: 'Spotlight on...' }]
    },
    {
      style: 'normal',
      children: [{ text: props.title }]
    },
  ],
  ...(props.type !== 'article'
    ? {
        to: (props.type !== 'video' && props.to) || null,
      }
    : props.article)
}))

function handleClick() {
  if (finalProps.value.to || props.type !== 'video') return

  active.value = true

  setTimeout(() => {
    videoElement.value?.play()
  }, 500)
}
</script>

<style lang="scss">
.card.spotlight {
  position: relative;
  border-radius: none;
  // width: 42rem;
  width: 100%;
  max-width: 100%;
  margin-right: auto;

  .spotlight {
    &-btn.card-btn {
      flex-flow: column;
      align-items: flex-start;
      padding: $md;
      font-weight: normal;
      z-index: 10;

      &.has-link {

        &:hover,
        &:focus,
        &:active {
          .spotlight-heading,
          .spotlight-button {
            color: var(--secondary);
          }
          .spotlight-image-element {
            transform: scale(1.1);
          }
        }
      }

    }
    &-video.card-video,
    &-video.card-video,
    &-image {
      width: 100%;
      height: 22rem;
      border-radius: var(--br-items-sm);
    }
    &-image {
      width: calc(100% + $lg);
      margin: 0 - $md 0 - $md $lg;
      overflow: hidden;

      &-element {
        width: 100%;
        height: 100%;
      }
    }
    &-video.card-video {
      z-index: 20;
    }
    &-heading {
      font-size: 2.4rem;
      margin: 0 0 $md;
      text-align: left;
      transition: transition(var(--transition), (transform, opacity, color));
    }

    &-button {
      .play-icon {
        width: 1em;
        margin-left: 0.4em;
      }
    }
  }

  // &::before,
  // &::after {
  //   position: absolute;
  //   width: 50vw;
  //   top: 0 - $lg - $md;
  //   bottom: 0 - $lg;
  //   background: $tint;
  //   z-index: 1;
  //   opacity: 0.2;
  //   content: '';
  // }
}

.section {
  .card.spotlight {
    &::before {
      right: 100%;
    }

    &::after {
      background: linear-gradient(90deg, $tint, rgba($tint, 0));
      // right: -20%;
    }
  }
}

.section-col-2-equal .section-column.second,
.section-col-2:not(.section-reverse) .section-column.sidebar,
.section-col-2.section-reverse .section-column.first {
  .card.spotlight {
    &::before {
      left: 100%;
    }

    &::after {
      background: linear-gradient(-90deg, $tint, rgba($tint, 0));
      // left: -20%;
    }
  }
}
</style>